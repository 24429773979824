import Vue from 'vue'
// import he from 'he'

export default (context, inject) => {
  Vue.filter('content', function (value) {
    return process.env.ASSET_URL + value
  })

  // Vue.filter('clearHtmlEntities', function (value) {
  //   if (value) {
  //     return he.decode(value.replace(/<\/?[^>]+(>|$)/g, '').replace(/\n/g, ''))
  //   }
  //   return ''
  // })

  inject('blockUI', function () {
    document.getElementsByTagName('html')[0].style.cursor = 'wait'
    document.getElementsByTagName('html')[0].style['pointer-events'] = 'none'
  })

  inject('releaseUI', function () {
    document.getElementsByTagName('html')[0].style.cursor = 'auto'
    document.getElementsByTagName('html')[0].style['pointer-events'] = 'auto'
  })

  inject('normalize', function (value) {
    return value
      .normalize('NFD')
      .replace(/[\u0300-\u036F]/g, '')
      .toLowerCase()
  })
}
