import Vue from 'vue'
// import he from 'he'

Vue.prototype.$icons = {
  add: 'plus-circle-outline',
  edit: 'text-box-edit-outline',
  chevronLeft: 'chevron-left',
  chevronRight: 'chevron-right',
  sort: 'sort-variant',
  trash: 'trash-can-outline',
}

Vue.prototype.$text = {
  noIdDelete: 'Atualização pendente',
}
