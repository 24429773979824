import Vue from 'vue'
import {
  Input,
  Datepicker,
  Dialog,
  Toast,
  Radio,
  Select,
  Autocomplete,
  Table,
  Icon,
  ConfigProgrammatic,
  Checkbox,
  Tooltip,
} from 'buefy'
// import 'buefy/dist/buefy.css'

Vue.use(Input)
Vue.use(Datepicker)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(Select)
Vue.use(Autocomplete)
Vue.use(Dialog)
Vue.use(Toast)
Vue.use(Table)
Vue.use(Icon)
Vue.use(Tooltip)
// Vue.prototype.$dialog = Dialog

ConfigProgrammatic.setOptions({
  defaultToastDuration: 5000,
  // defaultIconPack: 'material-icons',
  // ...
})
