import { ToastProgrammatic as Toast } from 'buefy'

export default function ({ $axios, store }) {
  $axios.onError((error) => {
    // console.log(navigator.onLine)
    // console.log(error)
    if (!navigator.onLine) {
      store.commit('addRequestInQueue')
      // throw new Error('Offline')
      console.log('is offline')
      Toast.open({
        message: 'Atualização pendente. Sem ligação à internet',
        type: 'is-info',
      })
      return Promise.resolve(false)
    } else {
      let errorMsg = 'Aconteceu um erro.'
      console.error(error)
      if (error.response) {
        errorMsg = error.response.data.message
      }
      Toast.open({
        message: errorMsg,
        type: 'is-danger',
      })
    }
  })
}
