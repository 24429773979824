/* eslint-disable eqeqeq */
export const state = () => ({
  utentes: [],
  problemas: [],
  contactos: [],
  consultas: [],
  alertas: [],
  dashboard: [],
  montes: [],
  requests: 0,
})

export const getters = {
  getUtentes: (state) => state.utentes,
  getProblemas: (state) => state.problemas,
  getContactos: (state) => state.contactos,
  getConsultas: (state) => state.consultas,
  getAlertas: (state) => state.alertas,
  getDashboard: (state) => state.dashboard,
  getMontes: (state) => state.montes,
  getQuestionarioId: (state) => (consulta, tipo) => {
    const c = state.consultas.find((c) => c.id == consulta)
    if (c === undefined) return null
    return c[tipo] || null
  },
}

export const mutations = {
  setUtentes(state, utentes) {
    state.utentes = utentes
  },
  setProblemas(state, problemas) {
    state.problemas = problemas
  },
  setContactos(state, contactos) {
    state.contactos = contactos
  },
  setConsultas(state, consultas) {
    state.consultas = consultas
  },
  setAlertas(state, alertas) {
    state.alertas = alertas
  },
  addAlerta(state, alerta) {
    state.alertas.push(alerta)
  },
  updateAlertaState(state, item) {
    const index = state.alertas.findIndex((c) => c.id == item.id)
    console.log('index', index)
    state.alertas[index].user_desactivou = item.user_desactivou
  },
  setDashboard(state, dashboard) {
    state.dashboard = dashboard
  },
  setMontes(state, montes) {
    state.montes = montes
  },
  addUtente(state, item) {
    state.utentes.push(item)
  },
  addProblema(state, item) {
    const index = state.utentes.findIndex((c) => c.id == item.parent_id)
    state.utentes[index].problemas.push(item)
  },
  updateProblema(state, item) {
    const index = state.utentes.findIndex((c) => c.id == item.parent_id)
    const problemaIdx = state.utentes[index]?.problemas.findIndex(
      (c) => c.id == item.id
    )
    state.utentes[index].problemas[problemaIdx] = item
  },
  addContactos(state, item) {
    // TODO notice this deletes the contact and the association,
    // in theory should only delete the association when we also
    // delete the association, for now the whole contact is deleted
    const index = state.utentes.findIndex((c) => c.id == item.parent_id)
    state.utentes[index].contactos.push({
      id: item.associatedId,
      contacto_id: item.id,
    })

    delete item.associatedId
    state.contactos.push(item)
  },
  editUtente(state, item) {
    const index = state.utentes.findIndex(
      (c) => Number(c.id) === Number(item.id)
    )
    state.utentes.splice(index, 1, item)
  },
  deleteProblema(state, item) {
    const index = state.utentes.findIndex(
      (c) => Number(c.id) === Number(item.parent_id)
    )
    const indexProblema = state.utentes[index].problemas.findIndex((c) => {
      return Number(c.id) === Number(item.id)
    })
    state.utentes[index].problemas.splice(indexProblema, 1)
  },
  deleteContactos(state, id) {
    const index = state.contactos.findIndex((c) => c.id === id)
    state.contactos.splice(index, 1)
  },
  addConsulta(state, item) {
    state.consultas.unshift(item)
  },
  addQuestionario(state, item) {
    const index = state.consultas.findIndex((c) => c.id === item.consulta_id)
    if (index === -1) return
    state.consultas[index][item.form] = item.id
  },
  addRequestInQueue(state) {
    state.requests = state.requests + 1
  },
  resetRequests(state) {
    state.requests = 0
  },
  // getUtenteProblemas(state, utente) {
  //   return state.utentes.find((c) => c.id === utente)?.problemas

  // }
}

export const actions = {
  // isto foi passado para o layout/default.vue para poder usar o fetch hook e esconder a app antes de carregar os dados
  // async fetchInitData({ commit }) {
  //   console.log('fetchInitData')
  //   try {
  //     const data = await this.$axios.get('init')
  //     commit('setUtentes', data.data.utentes)
  //     commit('setProblemas', data.data.problemas)
  //     commit('setContactos', data.data.contactos)
  //     commit('setConsultas', data.data.consultas)
  //     commit('setAlertas', data.data.alertas)
  //   } catch (error) {
  //     alert(error)
  //     console.log(error)
  //   }
  // },
  /*
  async fetchUtentes({ commit }) {
    console.log('fetchUtentes')
    try {
      const data = await this.$axios.get('utente')
      commit('setUtentes', data.data.data)
    } catch (error) {
      alert(error)
      console.log(error)
    }
  },
  */
}
