
// import axios from 'axios'
import AppNavbar from '~/components/AppNavbar.vue'

export default {
  name: 'DefaultLayout',
  components: { AppNavbar },
  data: () => ({
    wb: null,
    // This variable will save the event for later use.
    deferredPrompt: null,
  }),
  async fetch() {
    console.log('fetchInitData')
    if (!this.$auth.loggedIn) return
    try {
      const data = await this.$axios.get('init')
      this.$store.commit('setUtentes', data.data.utentes)
      this.$store.commit('setProblemas', data.data.problemas)
      this.$store.commit('setContactos', data.data.contactos)
      this.$store.commit('setConsultas', data.data.consultas)
      this.$store.commit('setAlertas', data.data.alertas)
      this.$store.commit('setDashboard', data.data.dashboard)
      this.$store.commit('setMontes', data.data.montes)
    } catch (error) {
      alert(error)
      console.error(error)
    }
  },
  async mounted() {
    const wb = await window.$workbox
    this.wb = wb
    if (wb) {
      window.addEventListener('beforeinstallprompt', (e) => {
        // Prevents the default mini-infobar or install dialog from appearing on mobile
        e.preventDefault()
        // Save the event because you'll need to trigger it later.
        this.deferredPrompt = e
        // Show your customized install prompt for your PWA
        // Your own UI doesn't have to be a single element, you
        // can have buttons in different locations, or wait to prompt
        // as part of a critical journey.
        console.log('prompt event')
        // showInAppInstallPromotion();
      })

      wb.addEventListener('installed', (event) => {
        // this.idb = await openDB('workbox-background-sync')
        // If we don't do this we'll be displaying the notification after the initial installation, which isn't perferred.
        if (event.isUpdate) {
          // whatever logic you want to use to notify the user that they need to refresh the page.
        }
      })
      wb.addEventListener('waiting', (event) => {
        console.log(
          `A new service worker has installed, but it can't activate` +
            `until all tabs running the current version have fully unloaded.`
        )
      })
      wb.addEventListener('message', (event) => {
        if (event.data.type === 'CACHE_UPDATED') {
          const { updatedURL } = event.data.payload
          console.log(`A newer version of ${updatedURL} is available!`)
        }
      })
    }
    window.addEventListener('online', (e) => {
      console.log('online')
      this.$store.commit('resetRequests')
    })
  },
  methods: {
    promptInstall() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt()

        this.deferredPrompt.userChoice.then((choice) => {
          console.log(choice)
          if (choice.outcome === 'accepted') {
            console.log('user accepted the prompt')
          }
          this.deferredPrompt = null
        })
      } else {
        console.log('no prompt')
      }
    },
    //   async teste() {
    //     const db = (this.idb = await openDB('workbox-background-sync'))
    //     db.getAll('requests').then(console.log)
    //     db.count('requests').then(console.log)
    //   },
  },
}
