
export default {
  data: () => ({
    search: null,
  }),
  computed: {
    searchUtentes() {
      const data = this.$store.getters.getUtentes
      return data.filter((item) => {
        const search = this.search?.toLowerCase()
        return (
          item.nome.toLowerCase().includes(search) ||
          item.alcunha.toLowerCase().includes(search) ||
          item.email.toLowerCase().includes(search) ||
          item.morada.toLowerCase().includes(search) ||
          item.cartao_cidadao_numero.toLowerCase().includes(search) ||
          item.nif.toLowerCase().includes(search) ||
          item.numero_utente_sns.toLowerCase().includes(search)
        )
      })
    },
  },
  methods: {
    async logout() {
      await this.$auth.logout()
      this.$router.push('/login')
    },
    selectUtente(option) {
      if (option) this.$router.push('/pacientes/' + option.id + '/dados')
    },
  },
}
