import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/pt_PT.json'

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    // eslint-disable-next-line import/namespace
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  })
})

// Add a custom rule.
extend('secret', {
  validate: (value) => value === 'example',
  message: 'This is not the magic word',
})
const isEmpty = (v) =>
  [false, null, undefined].includes(v) || !String(v).trim().length

extend('required_without', {
  params: ['target'],
  validate(value, { target }) {
    // true is valid, false is invalid
    return !isEmpty(value) || !isEmpty(target)
  },
  message: 'O campo {_field_} ou {target} devem ser preenchidos',
  computesRequired: true,
})

// Register it globally
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
